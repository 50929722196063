body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.templatemo-home {
  background: url('../src/assets/images/home_portal1.jpg');
  background-blend-mode: multiply;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  color: #5f5f5f;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;  
  height: 93vh;
  background-color: #1a1919df;
}
.templatemo-give {
  background: url('../src/assets/images/giving.jpg');
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center center;
  color: #5f5f5f;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;  
  height: 100vh;
  background-color: #1a1919df;
}

.footer{
  width: 100%;
  background: #228bdf;
  padding: 20px;
  text-align: center;
}

.overlay {
  position: absolute; 
  left: 0;
  top: 0;
  background-color: #1a1919da;
}
@media screen and ( max-width: 601px){
 .templatemo-home{
    height: 80vh;
  }
  .templatemo-give{
    height: 80vh;
  }
}
.account-img {
  height: 125px;
  width: 125px;
  margin-right: 20px;
  margin-bottom: 16px;
}
.error{
  color: #fc8181;
  margin-top: 2px;
}
.content {
  border-radius:.25em;
  box-shadow:0 0 .25em rgba(0,0,0,.25);
  box-sizing:border-box;
  text-align:center;
}

@media screen and (max-width: 600px){
  .content{
    width: 95%;
  }
}

.sidebar {
  margin-top: 20px;
  padding: 0;
  width: 24%;
  background-color: #ffffffcb;
  position: fixed;
  height: 100%;
  overflow: auto;
  
}

.sidebar a {
  display: block;
  color: #565656f4;
  padding: 16px;
  text-decoration: none;
}
 
.sidebar a.active {
  background-color: #228bdf;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

div.content {
  padding: 1px 16px;
}
.open-menu-button{
border: none;
background-color: rgba(0, 0, 0, 0.493);
color: rgb(255, 217, 0);
padding: 1% 10%;
font-size: 1.5em;
border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .sidebar {
    display: none;
    background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.3s;
  }
  .open-menu-button{
      display: block;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}
}
@media screen and (min-width: 601px){
    .open-menu-button{
      display: none;
  }
    #myNav{
        display: none;
    }
    .overlay{
        display: none;
    }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
.open-menu-button{
    border: none;
    background-color: rgba(0, 0, 0, 0.493);
    color: rgb(255, 217, 0);
    padding: 1% 10%;
    font-size: 1.5em;
    border-radius: 5px;
}
/* The Overlay (background) */
.overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */   
    height: 100%;
    width: 0%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    background-color: #000000e6; /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.3s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
    position: relative;

    top: 25%; /* 25% from the top */
    width: 100%; /* 100% width */
    text-align: center; /* Centered text/links */
    margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 200%;
    color: #78d8f5cb;
    display: block; /* Display block instead of inline */
    transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
    position: relative;
    top: 20px;
    right: 45px;
    font-size: 60px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
    font-size: 17px;
    top: 15px;
    right: 35px;
    }
}
@media screen and (max-width: 450px) {
    .overlay a {font-size: 17px}
    .overlay .closebtn {
    font-size: 30px;
    top: 15px;
    right: 35px;
    }
}

.after-overlay{
  max-width: 73%;
}

@media screen and (max-width: 600px){
  .after-overlay{
    max-width: 90%;
  }
  .sidebar{
    width: 0%;
  }
}

.forms{
  background: 'white';
}
.forms input{
  border-radius: 0px;
  margin: 0px
}

.forms select{
  border-radius: 0px;
  margin: 0px
}

.flex-container{
  display: grid;
  grid-template-columns: 5% 93%;
  justify-content: space-between;
}

.sideBarContainer{
  padding: 0;
  width: 180px;
  background-color: #ffffffcb;
  position: fixed;
  height: 100%;
  overflow: auto;
  z-index: 1;
  transition: visibility 0s linear, opacity 0.5s linear;
}
.main-content{
  z-index: 0;
  margin-left: 150px;
  margin-right: 40px;
  text-align: center;
}

.sideToggler{
  background: transparent;
  border: none;
}

.dashboardBody{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:flex-start;
  text-align: center;
  margin-left: 100px;
  margin-right: 20px;
}

.dashboardItem{
  flex-grow: 1;
  border-radius: 0px;
  margin: 5px;
  border: none;
  color: grey;
  text-decoration: none;
  font-weight: 400;
}

.generateBody{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}
.generateItem{
  flex-grow: 1;
  border-radius: 0px;
  margin: 5px;
  border: none;
  color: grey;
  text-decoration: none;
  font-weight: 400;
}

.rolesBody{
  max-width: 70%;
}

@media screen and (max-width: 600px){
  .sideBarContainer{
    display: none;
  }
  .main-content{
    margin-left: 15px;
    margin-right: 15px;
  }
  .dashboardBody{
    margin-left: 15px;
    margin-right: 15px;
  }
  .generateBody{
    margin-left: 15px;
    margin-right: 15px;
  }
  .rolesBody{
    max-width: 80%;
  }
}

@media screen and (min-width: 601px){
  .sideToggler{
    display: none;
  }
  .dashboardItem{
    max-width: 23%;
    min-width: 23%;
  }
  .generateItem{
    max-width: 40%;
    min-width: 40%;
  }
}

.loadingButton{
  border: none;
  background: transparent;
}
.authLoadingButton{
  border: none;
  background: transparent;
  left: 50%;
}

.dataBoard{
  background: transparent;
  border-color: #228bdf;
  box-shadow: none;
}

.query-btn{
  margin-top: 47px;
}

@media screen and (max-width: 601px){
  .query-btn{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.bookImg{
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 602px) {
  .bookImg{
    height: 350px;
  }
}

